import PropTypes from 'prop-types'
import React from 'react'
import td from '../images/td.png'


const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      {/* <img src={td} alt="Tripod Digital logo" width="64px"></img> */}
      <span className="icon fa-diamond"></span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>Tripod Digital</h1>
        <p>
          We <b>believe</b> you need to <b>stand out</b> in todays market place.{' '}
        </p>
        <p>
          To allow you to do so, we have created an <b>Augmented Reality</b> platform that <b>just works!</b>
        </p>
        <p>
          Make your brand or product come alive!
        </p>
                  <button onClick={ () => {window.open("https://mixr.tripod-digital.com",'_blank');}}>Start now</button>

      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            Intro
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Products
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
