import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'
import arbook from '../images/Intro_to_AR.mp4'
import picalive from '../images/picalive.mp4'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            With over 5 years experience in WebAR there is nothing we haven't 
            seen. We know the eco-system and we know what is possible to unleash 
            inside the browser. Let me assure you there is a lot that can be 
            accomplished using WebAR. 
          </p>
          <p>
            As WebAR is such a young medium there is some confusion out there 
            in regards to what can be done, what is WebXR, what about iPhone,
            I want to capture video, I would like to have a remote session.
            All that and more we have done before and we can help you achieve
            the same. So let me ask you one question.
            Why should you go through the hassle of creating a native App 
            which makes your customer to wait for it to download, which makes 
            you to have an expensive and time consuming development and 
            publishing effort, when you can simply create a WebAR app that 
            can be deployed and comsumed everywhere in an instant.
            </p>
            <p>
            WebAR is the future for single use and one-off 
            augmented reality experiences.
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Products</h2>
          <h3 className="major">PicAlive Pro</h3>
          <p>
            Bring your books or other content to life. Easy and effortlessly. We have taken care of every detail so you can focus on your book.
            Give your book an edge by utilizing state of the art Augmented Reality technology that runs in your browser. Also known as WebAR. No need to have your audiance install an App!
            Sign up to PicAlive Pro and connect your content with videos, 360 images and even 360 videos.
          </p>
          <span className="image main" style={{marginLeft: 'auto', marginRight: 'auto', width:'40%'}}>
            <video src={arbook} alt="" controls height="350"/>
          </span>
          <h3 className="major">PicAlive</h3>
          <p>
            Ever wondered what it would be like to have your invitation, greeting card, postcard come to life?
            Don't like writing cards but would love to send a video to your loved ones?
            Greeting cards are no longer limited to the static print image. Now you can add videos to your card. 
            It is very easy. 
            Check out <a href="https://picalive.web.app" target="_blank">PicAlive</a> and bring your card to life in less than 5 minutes.
          </p>
          <span className="image main" style={{marginLeft: 'auto', marginRight: 'auto', width:'40%'}}>
            <video src={picalive} alt="" controls height="400"/>
          </span>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
            Tripod Digital, is a leader creating content for augmented reality 
            in the browser. Also called WebAR. We are a team of software engineers,
            3D artists and creatives that breath and live WebAR.
          </p>
          <p>
            In our free time we support the opensource community and lead the opensource
            efforts around <a href="https://github.com/webarkit" target="_blank">webarkit</a>, jsartoolkit and WebAR Studio.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          {/* <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form> */}
          <p>
            Send us an email to <a href="mailto:info@tripod-digital.co.nz">thorsten@tripod-digital.co.nz</a>
          </p>
          <p>
            We are thrilled to get to know you and your ideas.
          </p>
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/ThorBux"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/tripod-digital" className="icon fa-linkedin">
                <span className="label">LinkedIn</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/webarkit"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
